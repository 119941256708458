import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { getAllConfigs } from '../../../actions/promotion/generals';
import {
  viewBasket,
  saveBasket,
  getBaskets,
} from '../../../actions/promotion/basketPlayground';
import InnerLoader from '../../../components/Common/InnerLoader';
import { formatCurrency } from '../../../utils/currency';
import { Else, If, Then } from 'react-if';
import { getAllFields } from '../../../actions/promotion/fields';
import { convertFieldToQueryBuilderFormat } from '../../../helpers/convertFieldToQueryBuilderFormat';
import { useSelector } from 'react-redux';

const BasketPlayground = ({
  promotionId,
  productEligibilityQuery,
  tiers,
  promotionDetail,
}) => {
  const [playgroundConfig, setPlaygroundConfig] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [sku, setSku] = useState(null);
  const [qty, setQty] = useState(null);
  const [products, setProducts] = useState([]);
  const [summary, setSummary] = useState({});
  const [baskets, setBaskets] = useState([]);
  const [fields, setFields] = useState([]);
  const [showBasketConfig, setShowBasketConfig] = useState(false);
  const { selectedComboForPreview } = useSelector(
    (state) => state.promotions || {}
  );
  const getFields = async () => {
    const { data } = await getAllFields({
      pageOffset: Math.max(0),
      pageSize: 2000,
    });

    setFields(convertFieldToQueryBuilderFormat(data));
  };

  const [basket, setBasket] = useState({
    promotionId: promotionId,
    configId: null,
    items: [],
    basketName: null,
  });

  useEffect(() => {
    getConfigs();
    getFields();
  }, []);

  useEffect(() => {
    if ((selectedConfig?._id && productEligibilityQuery, tiers)) {
      viewBasketFunc(basket);
    }
  }, [productEligibilityQuery, tiers, selectedComboForPreview]);

  useEffect(() => {
    if ((selectedConfig?._id && productEligibilityQuery, tiers)) {
      getBasketsFunc();
    }
  }, [selectedConfig]);
  useEffect(() => {
    if (!showBasketConfig) {
      viewBasketFunc(basket);
    }
  }, [showBasketConfig]);

  const getConfigs = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllConfigs({
        configName: 'basket-playground',
        configEnv: process.env.NODE_ENV,
        pageOffset: Math?.max(pageOffset - 1, 0),
        pageSize: 10,
      });
      setPlaygroundConfig([...data]);

      setBasket({ ...basket, configId: data?.[0]?._id });
      setSelectedConfig(data?.[0]);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const addProductToBasketFunc = async () => {
    try {
      setIsLoading(true);

      const existingItemIndex = basket?.items?.findIndex(
        (item) => item?.sku === sku
      );

      if (existingItemIndex === -1) {
        setBasket({ ...basket, items: [{ sku, qty }, ...basket?.items] });
        viewBasketFunc({ ...basket, items: [{ sku, qty }, ...basket?.items] });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewBasketFunc = async (basketParam) => {
    try {
      setIsLoading(true);
      // console.log('basketParambasketParam', basketParam);
      const skus = basketParam?.items?.map((item) => item) || [];
      if (skus?.length > 0 && productEligibilityQuery && tiers) {
        const data = {
          config: selectedConfig,
          basketAttributes: basketParam.basketAttributes,
          skus: selectedComboForPreview?.rule?.products
            ? selectedComboForPreview?.rule?.products
            : [...skus],
          productEligibilityQuery,
          tiers,
          selectedComboForPreview,
          promotionId,
          basicSettings: promotionDetail?.basicSettings,
        };
        clearTimeout(window.__timerViewBasket);
        window.__timerViewBasket = setTimeout(async () => {
          const res = await viewBasket(data);

          setProducts(res?.data?.items);
          setSummary(res?.data?.summary);
        }, 100);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveBasketFunc = async (basketData) => {
    try {
      const { updatedAt, createdAt, ...restData } = basketData;
      setIsLoading(true);
      const res = await saveBasket(restData);
      getBasketsFunc();
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const getBasketsFunc = async () => {
    try {
      setIsLoading(true);
      const data = {
        promotionId: promotionId,
        configId: selectedConfig?._id,
      };
      const res = await getBaskets(data);
      if (res?.data?.length > 0) {
        setBaskets([...res?.data]);
        viewBasketFunc({ ...res?.data?.[0] });
        setBasket({ ...res?.data?.[0] });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const incrementBasketItemQuantityByOne = (basketItemIndex) => {
    try {
      const bItems = basket?.items;
      bItems[basketItemIndex].qty = Number(bItems?.[basketItemIndex]?.qty) + 1;
      setBasket({
        ...basket,
        items: bItems,
      });
      viewBasketFunc({
        ...basket,
        items: bItems,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const decreaseBasketItemQuantityByOne = (basketItemIndex) => {
    try {
      const bItems = basket?.items;
      if (Number(bItems?.[basketItemIndex]?.qty) > 1) {
        bItems[basketItemIndex].qty =
          Number(bItems?.[basketItemIndex]?.qty) - 1;
        setBasket({
          ...basket,
          items: bItems,
        });
        viewBasketFunc({
          ...basket,
          items: bItems,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeItemsFromBasket = (sku) => {
    try {
      setProducts(products?.filter((fItem) => sku !== fItem?.id));
      const bItems = basket?.items?.filter((fItem) => sku !== fItem?.sku);
      setBasket({
        ...basket,
        items: bItems,
      });
      viewBasketFunc({
        ...basket,
        items: bItems,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const basketChangeHandler = (e) => {
    try {
      if (e?.target?.value) {
        setBasket(JSON?.parse(e?.target?.value));
        viewBasketFunc(JSON?.parse(e?.target?.value));
      } else {
        setBasket({
          promotionId: promotionId,
          configId: null,
          items: [],
          basketName: null,
        });
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const configChangeHandler = (e) => {
    try {
      if (e?.target?.value) {
        const config = JSON?.parse(e?.target?.value);
        setSelectedConfig(config);
        setBasket({
          ...basket,
          configId: config?._id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <Modal
        size="lg"
        isOpen={showBasketConfig}
        toggle={() => {
          setShowBasketConfig(!showBasketConfig);
        }}
      >
        <ModalBody>
          <Row>
            <Col lg={6}>
              <Row xl={12} lg={12} md={12} sm={12} xs={12} className="mt-2">
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="type">Basket Config</Label>
                    <Input
                      type="select"
                      id="type"
                      name="type"
                      onChange={(e) => {
                        configChangeHandler(e);
                      }}
                      value={JSON.stringify(selectedConfig)}
                    >
                      <option>--SELECT AN API--</option>
                      {playgroundConfig?.map((config, index) => {
                        return (
                          <option
                            value={JSON.stringify(config)}
                            key={`${index}`}
                          >
                            {config?.configLabel}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row xl={12} lg={12} md={12} sm={12} xs={12}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="type">Baskets</Label>
                    <Input
                      type="select"
                      id="type"
                      name="type"
                      onChange={(e) => {
                        basketChangeHandler(e);
                      }}
                      value={JSON.stringify(basket)}
                    >
                      {baskets?.length > 0 ? (
                        baskets?.map((bs, index) => {
                          return (
                            <option value={JSON.stringify(bs)} key={`${index}`}>
                              {bs?.basketName}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">New</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row xl={12} lg={12} md={12} sm={12} xs={12}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormGroup>
                    <Label for="type">Basket Name</Label>
                    <Input
                      type="text"
                      id="field-basket name"
                      placeholder="Basket Name"
                      onChange={(e) => {
                        setBasket({
                          ...basket,
                          basketName: e?.target?.value,
                        });
                      }}
                      value={basket?.basketName}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row xl={12} lg={12} md={12} sm={12} xs={12}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  {fields.map((field) => {
                    if (field.group === 'basket') {
                      return (
                        <>
                          <FormGroup>
                            <Label for={`field-backet-config-${field.name}`}>
                              {field.label}
                            </Label>
                            <Input
                              type="text"
                              id={`field-backet-config-${field.name}`}
                              placeholder={field.placeholder}
                              name={`field-backet-config-${field.name}`}
                              onChange={(e) => {
                                setBasket({
                                  ...basket,
                                  basketAttributes: {
                                    ...(basket.basketAttributes || {}),
                                    [field.name]: e?.target?.value,
                                  },
                                });
                              }}
                              value={basket.basketAttributes?.[field.name]}
                            />
                          </FormGroup>
                        </>
                      );
                    }
                    return null;
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setShowBasketConfig(!showBasketConfig);
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
      <If condition={basket?.basketName}>
        <Then>
          <If condition={productEligibilityQuery && tiers}>
            <Then>
              <Row
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pt-3 justify-content-between"
              >
                <Col>
                  <h5>{basket?.basketName}</h5>
                </Col>
                <Col className="col-auto">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      setShowBasketConfig(true);
                    }}
                  >
                    Edit config
                  </a>
                </Col>
              </Row>

              <div className="pt-3 ps-3 pb-3 bg-light border rounded-1">
                <Row xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormGroup>
                      <Input
                        type="text"
                        id="field-sku"
                        placeholder="SKU"
                        onChange={(e) => setSku(e?.target?.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={12}
                    sm={12}
                    xs={12}
                    className="ps-0 pe-0"
                  >
                    <FormGroup>
                      <Input
                        type="number"
                        id="field-qty"
                        placeholder="QTY"
                        onChange={(e) => setQty(e?.target?.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => addProductToBasketFunc()}
                    >
                      <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                      Add
                    </button>
                  </Col>
                </Row>
                {products?.map((item, index) => {
                  const basketItemIndex = basket?.items?.findIndex(
                    (bItem) => bItem?.sku == item?.id
                  );
                  return (
                    <div className="mt-4 shadow-lg">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div
                          className={`d-flex flex-row align-items-center p-2 justify-content-between ${
                            index != 0 ? '' : ''
                          } `}
                          style={{
                            background: '#fff',
                            width: '92%',
                            borderRadius: 6,
                          }}
                          key={`product-${index}`}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div>
                              <img
                                className="rounded me-2"
                                alt="80x80"
                                width="80"
                                src={`${item?.image}`}
                                data-holder-rendered="true"
                              />
                            </div>
                            <div className="d-flex flex-column ms-2">
                              <p className="fs-14 fw-medium text-muted mb-0">
                                {item?.name}
                              </p>
                              <p className="fs-11 text-muted mb-0 fw-medium">
                                SKU : {item?.id}
                              </p>
                              {/* <p className="fs-11 text-muted mb-0 fw-medium">Color : Black</p> */}
                              <div className="d-flex flex-row align-items-center">
                                {item?._ap > item?._sp ? (
                                  <span className="fs-11 text-muted mb-0 fw-normal text-decoration-line-through">
                                    {formatCurrency(item?._ap)}
                                  </span>
                                ) : null}
                                <span className="fs-12 text-muted mb-0 fw-medium ms-1">
                                  {`${formatCurrency(item._sp)}`}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex flex-row align-items-center mb-2 border border-gray rounded">
                            <div
                              className="ps-2 pe-2 pt-1 pb-1 cursor-pointer"
                              onClick={() => {
                                decreaseBasketItemQuantityByOne(
                                  basketItemIndex
                                );
                              }}
                            >
                              <i className="bx bx-minus font-size-16 align-middle"></i>
                            </div>
                            <div>{basket?.items?.[basketItemIndex]?.qty}</div>
                            <div
                              className="ps-2 pe-2 pt-1 pb-1 cursor-pointer"
                              onClick={() => {
                                incrementBasketItemQuantityByOne(
                                  basketItemIndex
                                );
                              }}
                            >
                              <i className="bx bx-plus font-size-16 align-middle"></i>
                            </div>
                          </div>
                        </div>

                        <div
                          className="p-2 me-1 cursor-pointer "
                          onClick={() => {
                            removeItemsFromBasket(item?.id);
                          }}
                        >
                          <i className="bx bi-x fs-4 align-middle"></i>
                        </div>
                      </div>

                      <div className="p-1 bg-light border rounded-1">
                        <div className="border-bottom pb-1 pt-1 p-2">
                          <div className="d-flex flex-row justify-content-between">
                            <span className="">Item Sub Total </span>
                            <span className="">
                              {formatCurrency(item._sp * item._qty || 0)}
                            </span>
                          </div>
                        </div>
                        <div className="border-bottom pb-1 pt-1">
                          <h6>Discount Total</h6>
                          <div className="d-flex flex-row justify-content-between">
                            <span>Total Item Discount</span>
                            <span>
                              {formatCurrency(item.result?.discountAmount || 0)}
                            </span>
                          </div>

                          <div className="d-flex flex-row justify-content-between">
                            <span>Total Item Weighted Discount</span>
                            <span>
                              {formatCurrency(
                                item.result?.discountProportional || 0
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="border-bottom pb-1 pt-1 p-2">
                          <div className="d-flex flex-row justify-content-between">
                            <span className="fw-bold">Item Grand Total </span>
                            <span className="fw-bold">
                              {formatCurrency(item.result?.itemGrandTotal || 0)}
                            </span>
                          </div>
                        </div>
                        {item?.result?.discountList?.map((discount, index) => {
                          return (
                            <div className="border-bottom pb-1 pt-1">
                              <div className="d-flex flex-row justify-content-between">
                                <span>Item Index</span>
                                <span>{discount?.productIndex}</span>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                <span>Promotion Name</span>
                                <span>{discount?.promotionName}</span>
                              </div>

                              <div className="d-flex flex-row justify-content-between">
                                <span>Applied Rule</span>
                                <span>Rule {discount?.ruleIndex + 1}</span>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                <span>Applied Effect</span>
                                <span>{discount?.effectType}</span>
                              </div>
                              <div className="d-flex flex-row justify-content-between">
                                <span>Discount Type</span>
                                <span>
                                  {discount?.discountValue}{' '}
                                  {discount?.discountType}
                                </span>
                              </div>

                              <If condition={discount?.unitBasedIndex != null}>
                                <div className="d-flex flex-row justify-content-between">
                                  <span>Applied Effect Unit Effect </span>
                                  <span>{discount?.unitBasedDesc}</span>
                                </div>
                              </If>
                              <div className="d-flex flex-row justify-content-between">
                                <span>Discount Amount</span>
                                <span>
                                  {formatCurrency(discount?.discountAmount)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-row justify-content-end mt-2 mb-2">
                <button
                  type="button"
                  className="btn btn-primary w-sm waves-effect waves-light"
                  onClick={() => {
                    saveBasketFunc(basket);
                  }}
                >
                  Save Basket
                </button>
                {basket?._id ? (
                  <button
                    type="button"
                    className="btn btn-primary w-sm waves-effect waves-light ms-3"
                    onClick={async () => {
                      const { _id, ...basketData } = basket;
                      saveBasketFunc(basketData);
                    }}
                  >
                    Save as New
                  </button>
                ) : null}
              </div>
              <div className="p-3 bg-light border rounded-1">
                <h6>Order Summary</h6>
                <div className="d-flex flex-row justify-content-between">
                  <span>Sub Total</span>
                  <span>{formatCurrency(summary?.subTotal)}</span>
                </div>
                {/* <div className="d-flex flex-row justify-content-between">
          <span>Shipping & handling</span>
          <span>2000</span>
        </div> */}
                <div className="d-flex flex-row justify-content-between">
                  <span>Discounts</span>
                  <span>{formatCurrency(summary?.totalDiscount)}</span>
                </div>
                <hr />
                {summary?.discountSummary?.map((discount, index) => {
                  return (
                    <div className="d-flex flex-row justify-content-between">
                      <span>{discount.summaryTitle}</span>
                      <span>{formatCurrency(discount?.discountAmount)}</span>
                    </div>
                  );
                })}
                <hr />
                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <h6 className="mb-0">Order Total</h6>
                    <span>(Inclusive of all taxes)</span>
                  </div>
                  <h6>{formatCurrency(summary?.finalValue)}</h6>
                </div>
              </div>
              {isLoading ? <InnerLoader /> : null}
            </Then>
            <Else>
              <div class="px-3 py-5 bg-light border rounded-1 border-soft-dark text-center">
                <h4>
                  You have chosen <b>{basket?.basketName}</b> Playground
                </h4>
                <p>
                  Set item eligibility query and atleast one rule to continue
                </p>
              </div>
            </Else>
          </If>
        </Then>
        <Else>
          <div class="px-3 py-5 bg-light border rounded-1 border-soft-dark text-center">
            <h4>Configure your Testing Playground for this promotion</h4>
            <p>You can simulate the promotion using this playground</p>
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light d-flex gap-1 m-auto"
              onClick={() => {
                setShowBasketConfig(true);
              }}
            >
              <i class="bx bx-plus fs-18"></i> Create a Playground
            </button>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default BasketPlayground;
