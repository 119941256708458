import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// Pages
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import UserProfile from '../pages/Authentication/Profile';
import UnAuthorized from '../pages/Authentication/UnAuthorized';
import NotFound from '../pages/Authentication/NotFound';
import Wallet from '../pages/Wallet';
import WalletDashboard from '../pages/Wallet/WalletDashboard';
import WalletTransactions from '../pages/Wallet/WalletTransactions';
import WalletUsersList from '../pages/Wallet/WalletUsersList';
import WalletUserDetails from '../pages/Wallet/WalletUserDetails';
import Ahlan from '../pages/Ahlan';
import RewardDashboard from '../pages/Reward/RewardDashboard';
import RewardTransactionTypes from '../pages/Reward/RewardTransactionTypes';
import RewardTransactions from '../pages/Reward/RewardTransactions';
import RewardApiAuth from '../pages/Reward/RewardAuth';
import RewardTierMaster from '../pages/Reward/RewardTierMaster';
import CreateTierMaster from '../pages/Ahlan/CreateTierMaster';
import CustomersList from '../pages/Customers';
import Profile from '../pages/Customers/Profile';
import Promotion from '../pages/Promotion';
import Fields from '../pages/Configuration/Fields';

import {
  ROUTE_ADMIN_FORGET_PASSWORD,
  ROUTE_USER_LOGIN,
  ROUTE_USER_LOGOUT,
  ROUTE_USER_LISTING,
  ROUTE_USER_CREATE,
  ROUTE_ROLE_LISTING,
  ROUTE_ROLE_CREATE,
  ROUTE_DASHBOARD,
  ROUTE_WALLET,
  ROUTE_WALLET_DASHBOARD,
  ROUTE_WALLET_TRANSACTIONS,
  ROUTE_WALLET_USERS_LIST,
  ROUTE_WALLET_USER_DETAILS,
  ROUTE_NOT_FOUND,
  ROUTE_PROFILE,
  ROUTE_REGISTER,
  ROUTE_UN_AUTHORIZED,
  ROUTE_LIST_ALL_CUSTOMERS,
  ROUTE_LIST_ALL_CUSTOMERS_DETAILS,
  ROUTE_EMAIL_VERIFIED,
  ROUTE_EXPORTS,
} from '../constants/route';
import PromotionDetail from '../pages/Promotion/PromotionDetail';
import General from '../pages/Configuration/General';
import Teams from '../pages/Configuration/Teams';
import Reward from '../pages/Reward/RewardTypes';
import RewardTypeDetails from '../pages/Reward/RewardTypes/RewardsMasterDetails';
import Users from '../pages/Auth/Users';
import CreateUsers from '../pages/Auth/Users/Create';
import Roles from '../pages/Auth/Roles';
import CreateRoles from '../pages/Auth/Roles/Create';
import EmailVerification from '../pages/Authentication/EmailVerification';
import ExportsList from '../pages/ExportsList';
import PromotionPrioritization from '../pages/Promotion/PromotionPrioritization';

export const useAllRoutes = () => {
  const [publicRoutes] = useState([
    { path: ROUTE_USER_LOGOUT, component: <Logout /> },
    { path: ROUTE_USER_LOGIN, component: <Login /> },
    { path: ROUTE_REGISTER, component: <Register /> },
    { path: ROUTE_ADMIN_FORGET_PASSWORD, component: <ForgetPasswordPage /> },
    { path: ROUTE_NOT_FOUND, component: <NotFound /> },
    { path: ROUTE_EMAIL_VERIFIED, component: <EmailVerification /> },
  ]);

  // Auth user routes
  const AUTH_USER_ROUTES = [
    {
      path: ROUTE_USER_LISTING,
      component: <Users />,
    },
    {
      path: ROUTE_USER_CREATE,
      component: <CreateUsers />,
    },
    // all user roles
    {
      path: ROUTE_ROLE_LISTING,
      component: <Roles />,
    },
    {
      path: ROUTE_ROLE_CREATE,
      component: <CreateRoles />,
    },
  ];

  // wallet
  const WALLET_ROUTES = [
    {
      path: ROUTE_WALLET,
      component: <Wallet />,
    },
    //  Wallet Dashboard
    {
      path: ROUTE_WALLET_DASHBOARD,
      component: <WalletDashboard />,
    },
    //  Wallet User List
    {
      path: ROUTE_WALLET_USERS_LIST,
      component: <WalletUsersList />,
    },
    //  Wallet User Details
    {
      path: ROUTE_WALLET_USER_DETAILS,
      component: <WalletUserDetails />,
    },
    //  Wallet User transaction
    {
      path: ROUTE_WALLET_TRANSACTIONS,
      component: <WalletTransactions />,
    },
  ];

  // promotion
  const PROMOTION_ROUTES = [
    {
      path: 'promotions',
      children: [
        {
          path: 'dashboard',
          component: <Promotion />,
        },
        {
          path: 'list',
          component: <Promotion />,
        },
        {
          path: 'prioritization',
          component: <PromotionPrioritization />,
        },
        {
          path: 'detail/new',
          exact: true,
          component: <PromotionDetail />,
        },
        {
          path: 'detail/:id',
          children: [
            {
              path: 'basic',
              component: <PromotionDetail />,
            },
            {
              path: 'schedule',
              component: <PromotionDetail />,
            },
            {
              path: 'coupons',
              component: <PromotionDetail />,
            },
            {
              path: 'conditions-effects',
              component: <PromotionDetail />,
            },
            {
              path: 'budgeting',
              component: <PromotionDetail />,
            },
            {
              path: 'cost-distribution',
              component: <PromotionDetail />,
            },
            {
              path: 'messages-and-notifications',
              component: <PromotionDetail />,
            },
            {
              path: 'redemptions',
              component: <PromotionDetail />,
            },
            {
              path: 'analysis',
              component: <PromotionDetail />,
            },
            {
              path: 'role',
              component: <PromotionDetail />,
            },
            {
              path: 'settings',
              component: <PromotionDetail />,
            },
          ],
        },
      ],
    },
  ];

  // reward types
  const REWARD_TYPES_ROUTES = [
    {
      path: 'reward-types',
      children: [
        {
          path: 'list',
          component: <Reward />,
        },
        {
          path: 'detail/new',
          exact: true,
          component: <RewardTypeDetails />,
        },
        {
          path: 'detail/:id',

          // component: <RewardTypeDetails />,
          children: [
            {
              path: 'basic',
              component: <RewardTypeDetails />,
            },
            {
              path: 'tiers',
              component: <RewardTypeDetails />,
            },
            {
              path: 'ptc-rate',
              component: <RewardTypeDetails />,
            },
            {
              path: 'redemption',
              component: <RewardTypeDetails />,
            },
            {
              path: 'media',
              component: <RewardTypeDetails />,
            },
          ],
        },
      ],
    },
  ];

  // customers
  const CUSTOMERS_ROUTES = [
    {
      path: ROUTE_LIST_ALL_CUSTOMERS,
      component: <CustomersList />,
    },
    // exports list
    {
      path: ROUTE_EXPORTS,
      component: <ExportsList />,
    },
    //  Customers Details
    {
      path: ROUTE_LIST_ALL_CUSTOMERS_DETAILS,
      component: <Profile />,
    },
  ];

  //Configurations
  const CONFIG_ROUTES = [
    {
      path: 'configurations',
      children: [
        {
          path: 'fields',
          component: <Fields />,
        },
        {
          path: 'general/:configName',
          component: <General />,
        },
        {
          path: 'teams',
          component: <Teams />,
        },
      ],
    },
  ];

  //  Reward
  const REWARD_ROUTES = [
    {
      path: 'rewards',
      children: [
        {
          path: `/rewards/:path/dashboard`,
          component: <RewardDashboard />,
        },
        {
          path: `/rewards/:path/transactions`,
          component: <RewardTransactions />,
        },
        {
          path: `/rewards/:path/transaction-types`,
          component: <RewardTransactionTypes />,
        },
        {
          path: `/rewards/:path/tire-master`,
          component: <RewardTierMaster />,
        },
        {
          path: `/rewards/:path/tire-master/create`,
          component: <CreateTierMaster />,
        },
        {
          path: `/rewards/:path/api-auth`,
          component: <RewardApiAuth />,
        },
      ],
    },
  ];

  const [authProtectedRoutes, setAuthProtectedRoutes] = useState([
    {
      path: '/',
      exact: true,
      component: <Navigate to={ROUTE_DASHBOARD} />,
    },
    { path: '*', component: <Navigate to={ROUTE_NOT_FOUND} /> },
    { path: ROUTE_UN_AUTHORIZED, component: <UnAuthorized /> },
    { path: ROUTE_PROFILE, component: <UserProfile /> },
    {
      path: ROUTE_DASHBOARD,
      component: <Dashboard />,
    },
    ...AUTH_USER_ROUTES,
    ...WALLET_ROUTES,
    ...PROMOTION_ROUTES,
    ...REWARD_TYPES_ROUTES,
    ...CUSTOMERS_ROUTES,
    ...CONFIG_ROUTES,
    ...REWARD_ROUTES,
  ]);

  return { publicRoutes, authProtectedRoutes };
};
