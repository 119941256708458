import React, { useState, useEffect } from 'react';
import PromotionReportConfig from '../PromotionReportConfig';

const PromotionAnalysis = ({
  promotionId,
  promotionDetail,
  fetchPromotionDetail,
}) => {
  return (
    <div className="p-3">
      <PromotionReportConfig
        promotionId={promotionId}
        promotionDetail={promotionDetail}
        fetchPromotionDetail={fetchPromotionDetail}
      />
    </div>
  );
};

export default PromotionAnalysis;
