import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import WalletTable from './WalletTable';
import Select from 'react-select';
import CreateCustomer from '../../../components/CreateCustomers/CreateCustomer';
import { COUNTRIES } from '../../../constants';
import { WALLET_STATUS } from '../../../constants';
import WalletMembersExportBtn from '../WalletTransactions/components/WalletMembersExportBtn';

const WalletUsersList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const resetSearch = () => {
    setSearchFilter(null);
    setCountryFilter(null);
    setStatusFilter(null);
  };

  const handleCustomerCreated = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  document.title = 'Wallet';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Wallet'} titleActive="Wallet Master" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <CreateCustomer onCustomerCreated={handleCustomerCreated} />
              </Col>
            </Row>
          </CardHeader>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <Row className="g-3">
              <Col>
                <div className={'search-box me-2 mb-2 d-inline-block w-100'}>
                  <Input
                    className="form-control search"
                    placeholder="Search by Wallet Id / mobile no"
                    type="text"
                    autoComplete="off"
                    value={searchFilter ?? ''}
                    onChange={(event) =>
                      setSearchFilter(event?.target?.value || '')
                    }
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
              <Col className="d-flex flex-wrap align-items-start gap-2">
                <Select
                  id="admin_status"
                  placeholder="Select a Country"
                  options={COUNTRIES}
                  onChange={(option) => setCountryFilter(option)}
                ></Select>

                <Select
                  id="admin_status"
                  placeholder="Select a Status"
                  options={WALLET_STATUS}
                  onChange={(option) => setStatusFilter(option)}
                ></Select>

                <Button
                  className="btn btn-outline  btn-light"
                  onClick={() => resetSearch()}
                >
                  <i className="ri-catalog-fill me-1 align-bottom"></i>
                  Reset
                </Button>

                <Button color={`primary`} className="btn-load">
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1 me-2">
                      <i className="ri-search-fill me-1 align-bottom"></i>
                      Search
                    </span>
                    {isLoading && (
                      <Spinner
                        size="sm"
                        className="flex-shrink-0"
                        role="status"
                      >
                        Loading...
                      </Spinner>
                    )}
                  </span>
                </Button>
                <div className="ms-auto">
                  <WalletMembersExportBtn
                  // dateRange={dateRangeFilter}
                  // status={selectedStatusFilter?.value}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <WalletTable
                  refreshKey={refreshKey}
                  search={searchFilter}
                  country={countryFilter?.value}
                  status={statusFilter?.value}
                  setIsLoading={setIsLoading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default WalletUsersList;
