// ROUTES
export const ROUTE_INDEX = '/index';
export const ROUTE_EMAIL_VERIFIED = '/email-verification';
export const ROUTE_USER_LOGIN = '/login';
export const ROUTE_USER_LOGOUT = '/logout';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';
export const ROUTE_REGISTER = '/register';
export const ROUTE_PROFILE = '/profile/:id';
export const ROUTE_NOT_FOUND = '/not-found';
export const ROUTE_ADMIN_FORGET_PASSWORD = '/forget-password';
export const ROUTE_DASHBOARD = '/dashboard';

// auth users
export const ROUTE_USER_LISTING = '/users';
export const ROUTE_USER_CREATE = '/users/create/:id';

export const ROUTE_ROLE_LISTING = '/roles';
export const ROUTE_ROLE_CREATE = '/roles/create/:id';

// wallet
export const ROUTE_WALLET = '/wallet';
export const ROUTE_WALLET_DASHBOARD = '/wallet/dashboard';
export const ROUTE_WALLET_TRANSACTIONS = '/wallet/transactions';
export const ROUTE_WALLET_USERS_LIST = '/wallet/list';
export const ROUTE_WALLET_USER_DETAILS =
  '/wallet/list/details/:country/:mobileNumber';
// ahlan
export const ROUTE_AHLAN = '/ahlan';
export const ROUTE_AHLAN_DASHBOARD = '/ahlan/dashboard';
export const ROUTE_AHLAN_TRANSACTIONS = '/ahlan/transactions';
export const ROUTE_AHLAN_TRANSACTION_TYPES = '/ahlan/transaction-types';
export const ROUTE_AHLAN_USERS_LIST = '/ahlan/members';
export const ROUTE_AHLAN_USER_DETAILS = '/ahlan/members/details';
export const ROUTE_AHLAN_TIER_MASTER = '/ahlan/tire-master';
export const ROUTE_AHLAN_CREATE_TIER_MASTER = '/ahlan/tire-master/create';
export const ROUTE_AHLAN_AUTHENTICATION = '/ahlan/api-auth';
// exports list
export const ROUTE_EXPORTS = '/exports/list';
// Customers
export const ROUTE_LIST_ALL_CUSTOMERS = '/members';
export const ROUTE_LIST_ALL_CUSTOMERS_DETAILS = '/members/profile/:id';
//promotion
export const ROUTE_PROMOTION = '/promotions';
export const ROUTE_PROMOTION_DASHBOARD = '/promotions/dashboard';
export const ROUTE_PROMOTION_LIST = '/promotions/list';
export const ROUTE_PROMOTION_DETAILS = '/promotions/detail/:id/basic';
export const ROUTE_PROMOTION_PRIORITIZATION = '/promotions/prioritization';

//configurations
export const ROUTE_CONFIGURATIONS = '/configurations';
export const ROUTE_CONFIGURATIONS_FIELDS = '/configurations/fields';
