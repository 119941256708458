// general
export const COMPANY_NAME = 'promo.danubehome.com';
export const DEVELOPER_NAME = 'Tech Team';
export const PROJECT_NAME = 'Promotion Service';

export const PROMOTION_API_SERVICE_BASE_URL =
  process.env.REACT_APP_PROMOTION_API_SERVICE_BASE_URL ||
  'http://localhost:4005/';

// user token
export const AUTH_TOKEN = 'token';

// allowed countries
export const COUNTRIES = [
  {
    label: 'United Arab Emirate',
    value: 'ae',
    flag: '🇦🇪',
    currency: 'AED',
  },
  {
    label: 'Qatar',
    value: 'qa',
    flag: '🇶🇦',
    currency: 'QAR',
  },
  {
    label: 'Bahrain',
    value: 'bh',
    flag: '🇧🇭',
    currency: 'BHD',
  },
  {
    label: 'Oman',
    value: 'om',
    flag: '🇴🇲',
    currency: 'OMR',
  },
  {
    label: 'Saudi Arabia',
    value: 'sa',
    flag: '🇸🇦',
    currency: 'SAR',
  },
  {
    label: 'Kuwait',
    value: 'kw',
    flag: '🇰🇼',
    currency: 'KWD',
  },
  {
    label: 'India',
    value: 'in',
    flag: '🇮🇳',
    currency: 'INR',
  },
];

export const DISCOUNT_TYPES = [
  {
    label: 'Percentage Discount',
    value: 'percentage',
  },
  {
    label: 'Fixed Discount',
    value: 'fixed',
  },
  {
    label: 'Fixed Price',
    value: 'fixed_price',
  },
];

export const EFFECT_TYPES = [
  {
    label: 'Transactions',
    value: 'transactions',
  },
  {
    label: 'Reward Points',
    value: 'rewardPoints',
  },
  {
    label: 'Wallet',
    value: 'wallet',
  },
  {
    label: 'Gift Voucher',
    value: 'giftVoucher',
  },
  {
    label: 'Coupons',
    value: 'coupons',
  },
];
const effectTypeObj = {};
EFFECT_TYPES.forEach((item) => {
  effectTypeObj[item.value] = item;
});

export const EFFECT_TYPES_OBJ = effectTypeObj;

export const WALLET_STATUS = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
  { value: '2', label: 'Blocked' },
];

export const STATUS_CLASS = { active: 'success', true: 'success' };

export const PROMOTION_REPORT_GROUP_BY_OPTIONS = [
  {
    label: 'Promotion Id',
    value: 'promotionId',
  },
  {
    label: 'Category Level1',
    value: 'categoryLevel1',
  },
  {
    label: 'Category Level2',
    value: 'categoryLevel2',
  },
  {
    label: 'Category Level3',
    value: 'categoryLevel3',
  },
  {
    label: 'Status',
    value: 'status',
  },
];
